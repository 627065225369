var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mockInterview"},[_vm._m(0),_c('div',{staticClass:"containers"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"priceBanner"},[_c('div',{staticClass:"priceBannerArea"},[_c('div',{staticClass:"priceBannerContent"},[_c('img',{attrs:{"src":require("@/assets/imgs/priceBanner.png"),"alt":""}}),_c('div',{staticClass:"priceBannerInner"},[_c('div',{staticClass:"priceLeft"},[_c('div',{staticClass:"prices"},[_c('span',{staticClass:"mainTitle"},[_vm._v(_vm._s(_vm.classInfo.courseName))]),_c('span',{staticClass:"subTitle"},[_vm._v("服务价格：")]),_c('span',{staticStyle:{"font-size":"28px","color":"#de1306","line-height":"42px","vertical-align":"top"}},[_vm._v(" $"+_vm._s(_vm.classInfo.usdAmount))]),_c('span',{staticStyle:{"font-size":"16px","color":"#e02020","line-height":"42px","vertical-align":"top"}},[_vm._v(" / ¥"+_vm._s(_vm.classInfo.amount))])])]),_c('div',{staticClass:"priceRight"},[_vm._m(5),_c('el-button',{staticClass:"buyNow",on:{"click":_vm.buyCombo}},[_vm._v("立即购买")])],1)])])])])]),_c('leetDialog')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagebanner"},[_c('img',{attrs:{"src":require("@/assets/mock/algorithmGuidance/indexBanner.png"),"alt":""}}),_c('div',{staticClass:"pageTitle"},[_vm._v(" 算法刷题面试指导 "),_c('div',{staticClass:"subtitle"},[_vm._v(" 3000+实战刷题经验导师指导，基于每一位学员当前算法能力现状 "),_c('br'),_vm._v(" 水平达标可内推北美各大公司，10家起步 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("北美公司内推，算法面试指导")]),_c('p',[_vm._v("测评算法面试水平，全方位查验针对性给予建议")])]),_c('div',{staticClass:"twoPlans"},[_c('div',{staticClass:"planone plan"},[_c('div',{staticClass:"imgplan"},[_c('img',{attrs:{"src":require("@/assets/mock/algorithmGuidance/plan1.png"),"alt":""}})]),_c('div',{staticClass:"textplan"},[_c('div',{staticClass:"title"},[_vm._v("明确北美大厂求职目标，评估算法能力差距")]),_c('p',[_vm._v(" 不论是零基础转专业，还是在职跳槽，对自己的当前能力和目标岗位有一个清晰的认知，能帮助你更快速的找到合适的自己的提升方式： ")]),_c('p',[_vm._v(" 分析求职者目标岗位所需达到的算法面试要求 "),_c('br'),_vm._v(" 分析求职目标岗位的面试结构 "),_c('br'),_vm._v(" 评估求职者当前算法基础 "),_c('br'),_vm._v(" 评估求职者当前算法刷题的能力水平 ")])])]),_c('div',{staticClass:"plantwo plan"},[_c('div',{staticClass:"textplan"},[_c('div',{staticClass:"title"},[_vm._v("1v1规划FMAG算法面试最快路径")]),_c('p',[_vm._v(" 根据求职者当前的现状，量身定制最快速的算法提升路径及刷题策略，有针对性的训练， 更快速进步： ")]),_c('p',[_vm._v(" 符合北美大厂的算法面试需求 "),_c('br'),_vm._v(" 针对性面试指导，综合提升算法知识储备 "),_c('br'),_vm._v(" 量身定制最快速有效刷的刷题路径 "),_c('br'),_vm._v(" 快速提升算法面试综合能力 ")])]),_c('div',{staticClass:"imgplan"},[_c('img',{attrs:{"src":require("@/assets/mock/algorithmGuidance/plan2.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("三大面试全面测评，对自己算法有清楚的认知")])]),_c('div',{staticClass:"fourSteps"},[_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",staticStyle:{"margin-bottom":"50px"},attrs:{"src":require("@/assets/mock/mockInterview/icon1.png"),"alt":""}}),_c('br'),_vm._v(" 逻辑能力 "),_c('div',{staticClass:"des"},[_vm._v(" 能否见题有思路 "),_c('br'),_vm._v(" 通过提示能否想出idea ")])]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon2.png"),"alt":""}}),_c('br'),_vm._v(" 写码能力 "),_c('div',{staticClass:"des"},[_vm._v(" 代码书写能力 "),_c('br'),_vm._v(" 把想法转变为代码逻辑 ")])]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",staticStyle:{"margin-bottom":"38px"},attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 表达能力 "),_c('div',{staticClass:"des"},[_vm._v(" 临场面试表达 "),_c('br'),_vm._v(" 写码表达和思维逻辑表达 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section gray"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("服务形式")]),_c('div',{staticClass:"title-description"},[_vm._v("1v1服务，单次120～150分钟")]),_c('div',{staticClass:"title-description"},[_vm._v("Edward老师测评")]),_c('div',{staticClass:"title-description"},[_vm._v(" 测评水平合格可内推各大公司，10家起步 ")]),_c('div',{staticClass:"title-description"},[_vm._v(" 全方位水平评估，最完整的算法能力体现 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("快速报名，获取你的专属定制面试策略")])]),_c('div',{staticClass:"fourSteps"},[_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon1.png"),"alt":""}}),_c('br'),_vm._v(" 免费咨询 "),_c('br'),_vm._v(" 定制专属服务 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon2.png"),"alt":""}}),_c('br'),_vm._v(" 在线支付 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 填写表单 "),_c('br'),_vm._v(" 精准推荐面试官 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon4.png"),"alt":""}}),_c('br'),_vm._v(" 1v1结构化面试 "),_c('br'),_vm._v(" 收获完整面试报告 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricesTips"},[_c('div',{staticClass:"dollar"},[_vm._v("支持美元分期付款")]),_c('div',{staticClass:"huabei"},[_vm._v("支持花呗分期")])])
}]

export { render, staticRenderFns }