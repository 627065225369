<template>
<!-- 算法刷题面试指导 -->
  <div class="mockInterview">
    <div class="pagebanner">
      <img src="@/assets/mock/algorithmGuidance/indexBanner.png" alt="" />
      <div class="pageTitle">
        算法刷题面试指导
        <div class="subtitle">
          3000+实战刷题经验导师指导，基于每一位学员当前算法能力现状
          <br />
          水平达标可内推北美各大公司，10家起步
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="g-section">
        <div class="g-header">
          <h3>北美公司内推，算法面试指导</h3>
          <p>测评算法面试水平，全方位查验针对性给予建议</p>
        </div>
        <div class="twoPlans">
          <div class="planone plan">
            <div class="imgplan">
              <img src="@/assets/mock/algorithmGuidance/plan1.png" alt="" />
            </div>
            <div class="textplan">
              <div class="title">明确北美大厂求职目标，评估算法能力差距</div>
              <p>
                不论是零基础转专业，还是在职跳槽，对自己的当前能力和目标岗位有一个清晰的认知，能帮助你更快速的找到合适的自己的提升方式：
              </p>
              <p>
                分析求职者目标岗位所需达到的算法面试要求
                <br />
                分析求职目标岗位的面试结构
                <br />
                评估求职者当前算法基础
                <br />
                评估求职者当前算法刷题的能力水平
              </p>
            </div>
          </div>
          <div class="plantwo plan">
            <div class="textplan">
              <div class="title">1v1规划FMAG算法面试最快路径</div>
              <p>
                根据求职者当前的现状，量身定制最快速的算法提升路径及刷题策略，有针对性的训练，
                更快速进步：
              </p>
              <p>
                符合北美大厂的算法面试需求
                <br />
                针对性面试指导，综合提升算法知识储备
                <br />
                量身定制最快速有效刷的刷题路径
                <br />
                快速提升算法面试综合能力
              </p>
            </div>
            <div class="imgplan">
              <img src="@/assets/mock/algorithmGuidance/plan2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="g-section">
        <div class="g-header">
          <h3>三大面试全面测评，对自己算法有清楚的认知</h3>
        </div>
        <div class="fourSteps">
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon1.png"
              alt=""
              style="margin-bottom: 50px"
            />
            <br />
            逻辑能力
            <div class="des">
              能否见题有思路
              <br />
              通过提示能否想出idea
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon2.png"
              alt=""
            />
            <br />
            写码能力
            <div class="des">
              代码书写能力
              <br />
              把想法转变为代码逻辑
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon3.png"
              alt=""
              style="margin-bottom: 38px"
            />
            <br />
            表达能力
            <div class="des">
              临场面试表达
              <br />
              写码表达和思维逻辑表达
            </div>
          </div>
        </div>
      </div>
      <div class="g-section gray">
        <div class="g-header">
          <h3>服务形式</h3>
          <div class="title-description">1v1服务，单次120～150分钟</div>
          <div class="title-description">Edward老师测评</div>
          <div class="title-description">
            测评水平合格可内推各大公司，10家起步
          </div>
          <div class="title-description">
            全方位水平评估，最完整的算法能力体现
          </div>
        </div>
      </div>
      <div class="g-section">
        <div class="g-header">
          <h3>快速报名，获取你的专属定制面试策略</h3>
        </div>
        <div class="fourSteps">
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon1.png"
              alt=""
            />
            <br />
            免费咨询
            <br />
            定制专属服务
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon2.png"
              alt=""
            />
            <br />
            在线支付
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon3.png"
              alt=""
            />
            <br />
            填写表单
            <br />
            精准推荐面试官
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon4.png"
              alt=""
            />
            <br />
            1v1结构化面试
            <br />
            收获完整面试报告
          </div>
        </div>
      </div>

      <div class="priceBanner">
        <div class="priceBannerArea">
          <div class="priceBannerContent">
            <img src="@/assets/imgs/priceBanner.png" alt="" />
            <div class="priceBannerInner">
              <div class="priceLeft">
                <div class="prices">
                  <span class="mainTitle">{{ classInfo.courseName }}</span>
                  <span class="subTitle">服务价格：</span>
                  <span
                    style="
                      font-size: 28px;
                      color: #de1306;
                      line-height: 42px;
                      vertical-align: top;
                    "
                  >
                    ${{ classInfo.usdAmount }}</span
                  >
                  <span
                    style="
                      font-size: 16px;
                      color: #e02020;
                      line-height: 42px;
                      vertical-align: top;
                    "
                    >&nbsp;/&nbsp;&yen;{{ classInfo.amount }}</span
                  >
                </div>
              </div>
              <div class="priceRight">
                <div class="pricesTips">
                  <div class="dollar">支持美元分期付款</div>
                  <div class="huabei">支持花呗分期</div>
                </div>
                <el-button class="buyNow" @click="buyCombo"
                  >立即购买</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<leetDialog></leetDialog>
  </div>
</template>
<script>
import mockMix from "@/utils/mockMix";
import leetDialog from "@/components/leetDialog.vue";
export default {
  components: {
  	leetDialog
  },
  name: "Index",
  data() {
    return {};
  },
  mixins: [mockMix],
};
</script>
<style scoped lang="scss">
.pagebanner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .pageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    .subtitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.g-section {
  width: 100%;
  margin: 0 auto;
  padding: 36px 0;
  background: #f8f8f8;
  &.gray {
    background: rgba(240, 241, 242, 0.8);
  }
  .g-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .g-header h3 {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .g-header p {
    margin-top: 8px;
    color: #676767;
    font-size: 16px;
    margin-bottom: 48px;
  }
}

.plans {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .plan {
    width: 520px;
    padding: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 30px;
    img {
      vertical-align: top;
      width: 146px;
    }
    .plan-rightText {
      display: inline-block;
      width: calc(100% - 146px);
      .plan-title {
        padding: 16px 0;
        padding-top: 32px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e59f3c;
        line-height: 24px;
      }
      .plan-description {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }
    }
  }
}
.threeInterviews {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .interviews {
    text-align: center;
  }
  .interviewIcon {
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    padding: 30px;
    margin: 0 60px;
    img {
      width: 42px;
      height: 42px;
      vertical-align: top;
    }
  }
  .interviewType {
    margin-top: 20px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e59f3c;
    line-height: 27px;
  }
}
.fourSteps {
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .arrow,
  .step {
    margin: 0 24px;
  }
  .step {
    text-align: center;
    padding: 62px 54px;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 28px;
    .des {
      margin-top: 8px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #757575;
      line-height: 20px;
    }
  }
  .arrow {
    line-height: 166px;
    img {
      width: 24px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.title-description {
  margin-top: 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 24px;
}
.getInIcon {
  width: 36px;
  margin-bottom: 24px;
}

.priceBanner {
  width: 100%;
  min-height: 60px;
  position: fixed;
  bottom: 50px;
  z-index: 99;
}
.priceBannerArea {
  width: 1200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.priceBannerContent {
  position: relative;
}
.priceBannerInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}
.pricesTips .dollar {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
}
.pricesTips .huabei {
  background: rgba(10, 122, 255, 0.1);
  color: #0a7aff;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
}
.priceBannerInner .prices {
  padding-bottom: 0px;
  color: #ff3b30;
  font-size: 24px;
  min-height: 30px;
  .mainTitle {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 42px;
    margin-right: 30px;
  }
  .subTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 42px;
    vertical-align: top;
    margin-right: 16px;
  }
}
.priceLeft,
.priceRight {
  display: inline-block;
}
.priceLeft {
  padding-left: 60px;
}
.priceRight {
  float: right;
  padding-right: 82px;
}
.priceRight .classHelp {
  line-height: 35px;
  background: #ff9f0a;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceRight .buyNow {
  line-height: 35px;
  background: #ff3b30;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceBannerContent img {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
.pricesTips {
  display: inline-block;
  margin-right: 12px;
}
.twoPlans {
  width: 900px;
  margin: 0 auto;
  .textplan {
    width: calc(100% - 206px);
    padding: 18px 12px;
  }
  .imgplan {
    width: 206px;
  }
  .plan {
    padding: 36px 60px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 40px;
    display: flex;
    flex-wrap: nowrap;
    img {
      width: 206px;
      height: 206px;
      vertical-align: top;
    }
    .title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #e59f3c;
      line-height: 27px;
      margin-bottom: 16px;
    }
    p {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
      margin-bottom: 12px;
    }
  }
}
</style>